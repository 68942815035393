import React from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import ScholarCard from './ScholarCard';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import ManImg from '../../Assets/Images/young-caucasian-man 2.png';
import OrangeImg from '../../Assets/Images/orange.png';
import TerryImg from '../../Assets/Images/terry.png';
import HappyGradImg from '../../Assets/Images/happy grad.png';

function GetScholarships() {

    return (
        <div className='get-scholarships'>
            <div className='wrapper tw-my-8 tw-py-8 tw-grid tw-grid-cols-2 tablet:tw-grid-cols-1 mobile:tw-flex'>
                <div className='tw-content-center tw-py-52 tablet:tw-py-4'>
                    <p className=''>UNIFINDERS SCHOLARSHIPS</p>
                    <h1 className='heading1 bold'>Get Scholarships</h1>
                    <h1 className='heading1 bold'>worth 7,00,00,000*</h1>
                    <p className='tw-py-10 tw-pr-[15%]'>The easiest way to apply and get enrolled at international universities. Get in realtime and stay updated regarding your progress.</p>
                    <PrimaryButton className='tw-py-4 tw-px-8 tw-rounded-xl tw-bg-white'>
                        <div className='tw-flex tw-nowrap tw-gap-2'> <span className='text-secondary'> Explore Scholarship Programs</span>
                            <ArrowRightIcon className='tw-h-6 tw-w-6 text-secondary' />
                        </div>
                    </PrimaryButton>
                </div>
                <div className='tw-relative tw-h-full tablet:tw-min-h-screen'>
                    <div className='tw-absolute tw-top-20 mobile:tw-static mobile:tw-my-4'>
                        <ScholarCard name={'Kierra Schleifer'} rating={4.8} country={'Australia'} image={HappyGradImg} />
                    </div>
                    <div className='tw-absolute tw-left-80 mobile:tw-static mobile:tw-my-4'>
                        <ScholarCard name={'Terry Mango'} rating={4.9} country={'Australia'} image={TerryImg} />
                    </div>
                    <div className='tw-absolute tw-top-[430px] mobile:tw-static mobile:tw-my-4'>
                        <ScholarCard name={'Jaylon Baptista'} rating={3.9} country={'Australia'} image={ManImg} />
                    </div>
                    <div className='tw-absolute tw-top-[350px] tw-left-80 mobile:tw-static mobile:tw-my-4'>
                        <ScholarCard name={'Alena Bator'} rating={4.2} country={'Australia'} image={OrangeImg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetScholarships;
