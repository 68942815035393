import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import password from '../../Assets/Images/icons/password.png'
import congrats from '../../Assets/Images/congrats.png'
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Required'),
    username: Yup.string().required('Required'),
    fullName: Yup.string().required('Required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
});

const signInValidationSchema = Yup.object({
    username: Yup.string().required('Required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
});

const OTPValidationSchema = Yup.object({
    otp: Yup.string()
        .matches(/^\d{7}$/, 'OTP must be exactly 7 digits')
        .required('OTP is required')
});

const LOGIN_STATES = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    OTP_VERIFY: 'OTP_VERIFY',
    CONGRATULATION: 'CONGRATULATION',
}

export default function Login() {
    const navigate = useNavigate();
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [loginState, setIsLoginState] = useState(LOGIN_STATES.LOGIN);

    const handlePaste = (event, setFieldValue) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (/^\d{7}$/.test(pastedData)) {
            setFieldValue('otp', pastedData);
            const inputs = document.querySelectorAll('input');
            inputs.forEach((input, index) => {
                input.value = pastedData[index] || '';
            });
            if (inputs[0]) inputs[0].focus();
        }
    };

    const handleOTPSubmit = (values, { setSubmitting, setStatus }) => {
        const data = JSON.stringify({
            otp: values.otp
        });

        const config = {
            method: 'post',
            url: `http://localhost:8080/auth/verify-user?email=${userEmail}&isVerify=true`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                toast.success('User verified successfully!');
                setIsLoginState(LOGIN_STATES.CONGRATULATION)
            })
            .catch(error => {
                console.error(error);
                toast.error('Failed to verify user');
            })
            .finally(() => setSubmitting(false));
    };

    const handleSubmit = (values, { setSubmitting, setStatus }) => {
        const data = JSON.stringify({
            email: values.email,
            phone: values.phone,
            username: values.username,
            fullName: values.fullName,
            password: values.password,
            userStatus: 'VERIFIED',
            isVerify: true,
            imageUrl: 'string',
            role: { role: 'ADMIN' }
        });

        const config = {
            method: 'post',
            url: 'http://localhost:8080/auth/register-user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                toast.success('User registered successfully!');
                setUserEmail(values.email)
                setIsLoginState(LOGIN_STATES.OTP_VERIFY)
            })
            .catch(error => {
                console.error(error);
                toast.error('Failed to register user');
            })
            .finally(() => setSubmitting(false));
    };

    const handleSignInSubmit = (values, { setSubmitting, setStatus }) => {
        const data = JSON.stringify({
            username: values.username,
            password: values.password,
        });

        const config = {
            method: 'post',
            url: 'http://localhost:8080/auth/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                toast.success('Logged in successfully!');
                navigate('/');
            })
            .catch(error => {
                console.error(error);
                toast.error('Failed to login!')
            })
            .finally(() => setSubmitting(false));
    };

    const handleClick = (external_ip) => {
        const callbackUrl = `${window.location.origin}`;
        window.location.href = `http://localhost:8080/oauth2/authorize/${external_ip}?redirect_uri=${encodeURIComponent(
            callbackUrl
        )}`;
    };

    useEffect(() => {
        if (isLoggedin) {
            navigate("/");
        }
    }, [isLoggedin, navigate]);

    return (
        <div className="login-container">
            <div className="login">
                {loginState === LOGIN_STATES.CONGRATULATION ? (
                    <div onClick={() => navigate('/')} className="tw-m-auto tw-w-fit">
                        <img src={congrats} />
                    </div>
                ) : <div className="tw-grid tw-grid-cols-11">
                    <div onClick={() => navigate('/')} className="side-banner tw-col-span-5 mobile:tw-hidden">
                    </div>
                    <div className="tw-col-span-6 mobile:tw-col-span-11 tw-p-8">
                        {loginState === LOGIN_STATES.OTP_VERIFY ? (<div className="tw-flex tw-justify-center tw-p-4">
                            <img src={password} alt="****" />
                        </div>) : (<p className="text-secondary">Welcome to Unifinders!</p>)}
                        <h1 className="heading3">{loginState === LOGIN_STATES.LOGIN ? "Log into your account" : loginState === LOGIN_STATES.REGISTER ? "Create your Account" : (

                            <div className="tw-text-center">OTP Verification!</div>
                        )}</h1>
                        {loginState === LOGIN_STATES.OTP_VERIFY ? (<p className="tw-text-center tw-p-4">
                            We have sent you a text message with 6-digit verification code to
                            <div className="text-secondary">{userEmail}</div>
                        </p>) : null}
                        <div className="tw-py-6">

                            {loginState === LOGIN_STATES.LOGIN ? (
                                <>
                                    <Formik
                                        enableReinitialize={true}

                                        initialValues={{
                                            username: '',
                                            password: ''
                                        }}
                                        validationSchema={signInValidationSchema}
                                        onSubmit={handleSignInSubmit}
                                    >
                                        {({ isSubmitting, status, handleSubmit }) => (
                                            <Form className="tw-space-y-4">


                                                <div>
                                                    <label htmlFor="username" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Username</label>
                                                    <Field type="text" name="username" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                    <ErrorMessage name="username" component="div" className="tw-text-red-500 tw-text-sm" />
                                                </div>

                                                <div>
                                                    <label htmlFor="password" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Password</label>
                                                    <Field type="password" name="password" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                    <ErrorMessage name="password" component="div" className="tw-text-red-500 tw-text-sm" />
                                                </div>

                                                <div className="tw-pb-2">
                                                    <input type="checkbox"></input>
                                                    <label> Remember me</label>
                                                </div>

                                                <button onClick={handleSubmit} disabled={isSubmitting} className="primary-button tw-w-full tw-py-4">
                                                    Login
                                                </button>

                                                {status && status.success && <div className="tw-text-green-500">{status.success}</div>}
                                                {status && status.error && <div className="tw-text-red-500">{status.error}</div>}
                                            </Form>
                                        )}
                                    </Formik>
                                    <div className="tw-py-10">
                                        <div className="dash tw-text-center">or</div>
                                    </div>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                        <button className="secondary-button tw-py-4 tw-w-full" onClick={() => handleClick('google')}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 326667 333333"
                                                shapeRendering="geometricPrecision"
                                                textRendering="geometricPrecision"
                                                imageRendering="optimizeQuality"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                width={20}
                                                height={20}
                                            >
                                                <path
                                                    d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
                                                    fill="#4285f4"
                                                />
                                                <path
                                                    d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
                                                    fill="#34a853"
                                                />
                                                <path
                                                    d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
                                                    fill="#fbbc04"
                                                />
                                                <path
                                                    d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
                                                    fill="#ea4335"
                                                />
                                            </svg>
                                            <span className="tw-px-2">
                                                Google
                                            </span>
                                        </button>
                                        <button className="secondary-button tw-py-4 tw-w-full" onClick={() => handleClick('facebook')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 48 48">
                                                <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
                                            </svg>
                                            <span className="tw-px-2">
                                                Facebook
                                            </span>
                                        </button>
                                    </div>
                                </>
                            ) :
                                loginState === LOGIN_STATES.REGISTER ? (
                                    <>
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                            <button className="secondary-button tw-py-4 tw-w-full" onClick={() => handleClick('google')}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 326667 333333"
                                                    shapeRendering="geometricPrecision"
                                                    textRendering="geometricPrecision"
                                                    imageRendering="optimizeQuality"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    width={20}
                                                    height={20}
                                                >
                                                    <path
                                                        d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
                                                        fill="#4285f4"
                                                    />
                                                    <path
                                                        d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
                                                        fill="#34a853"
                                                    />
                                                    <path
                                                        d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
                                                        fill="#fbbc04"
                                                    />
                                                    <path
                                                        d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
                                                        fill="#ea4335"
                                                    />
                                                </svg>
                                                <span className="tw-px-2">
                                                    Google
                                                </span>
                                            </button>
                                            <button className="secondary-button tw-py-4 tw-w-full" onClick={() => handleClick('facebook')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 48 48">
                                                    <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
                                                </svg>
                                                <span className="tw-px-2">
                                                    Facebook
                                                </span>
                                            </button>
                                        </div>
                                        <div className="tw-py-10">
                                            <div className="dash tw-text-center">or</div>
                                        </div>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                email: '',
                                                phone: '',
                                                username: '',
                                                fullName: '',
                                                password: ''
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSubmit}
                                        >
                                            {({ isSubmitting, status, handleSubmit }) => (
                                                <Form className="tw-space-y-4">

                                                    <div className="tw-flex tw-gap-6">
                                                        <div className="tw-flex-col">
                                                            <label htmlFor="fullName" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Full Name</label>
                                                            <Field type="text" name="fullName" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                            <ErrorMessage name="fullName" component="div" className="tw-text-red-500 tw-text-sm" />
                                                        </div>

                                                        <div className="tw-flex-col">
                                                            <label htmlFor="username" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Username</label>
                                                            <Field type="text" name="username" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                            <ErrorMessage name="username" component="div" className="tw-text-red-500 tw-text-sm" />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Email</label>
                                                        <Field type="email" name="email" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                        <ErrorMessage name="email" component="div" className="tw-text-red-500 tw-text-sm" />
                                                    </div>

                                                    <div>
                                                        <label htmlFor="phone" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Phone</label>
                                                        <Field type="text" name="phone" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                        <ErrorMessage name="phone" component="div" className="tw-text-red-500 tw-text-sm" />
                                                    </div>

                                                    <div>
                                                        <label htmlFor="password" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Password</label>
                                                        <Field type="password" name="password" className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-indigo-500 tw-focus:border-indigo-500 tw-sm:text-sm" />
                                                        <ErrorMessage name="password" component="div" className="tw-text-red-500 tw-text-sm" />
                                                    </div>

                                                    <div className="tw-pb-2" onClick={() => setAgreeTermsAndCondition(!agreeTermsAndCondition)}>
                                                        <input value={agreeTermsAndCondition} type="checkbox"></input>
                                                        <label> I agree to Terms & Condition and Privacy Policy </label>
                                                    </div>

                                                    <button onClick={handleSubmit} disabled={isSubmitting || !agreeTermsAndCondition} className="primary-button tw-w-full tw-py-4 disabled:tw-opacity-65">
                                                        Register
                                                    </button>

                                                    {status && status.success && <div className="tw-text-green-500">{status.success}</div>}
                                                    {status && status.error && <div className="tw-text-red-500">{status.error}</div>}
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                ) : (
                                    <Formik
                                        initialValues={{ otp: '' }}
                                        validationSchema={OTPValidationSchema}
                                        onSubmit={handleOTPSubmit}
                                    >
                                        {({ setFieldValue, values }) => (
                                            <Form>
                                                <div className="tw-flex tw-space-x-2 tw-mb-4 tw-justify-center">
                                                    {[...Array(7)].map((_, index) => (
                                                        <Field
                                                            key={index}
                                                            name={`otp[${index}]`}
                                                            type="text"
                                                            maxLength="1"
                                                            className="tw-w-12 tw-h-12 tw-text-center tw-text-xl tw-border tw-border-gray-300 tw-rounded tw-focus:outline-none tw-focus:border-blue-500"
                                                            value={values.otp[index] || ''}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                if (/^\d?$/.test(value)) { // Allow digits and empty values
                                                                    // Update Formik field values
                                                                    const updatedValue = [...values.otp];
                                                                    updatedValue[index] = value;
                                                                    setFieldValue('otp', updatedValue.join(''));
                                                                    // Move focus to the next input if available
                                                                    if (value && index < 6) {
                                                                        e.target.nextElementSibling.focus();
                                                                    }
                                                                } else if (e.nativeEvent.inputType === 'deleteContentBackward' && index > 0) {
                                                                    // Handle deletion (backspace)
                                                                    setFieldValue('otp', values.otp.slice(0, index) + values.otp.slice(index + 1));
                                                                    // Move focus to the previous input if available
                                                                    e.target.previousElementSibling?.focus();
                                                                }
                                                            }}
                                                            onPaste={(e) => handlePaste(e, setFieldValue)}
                                                        />
                                                    ))}
                                                </div>
                                                <ErrorMessage name="otp">
                                                    {msg => <div className="tw-text-red-500 tw-text-sm tw-mb-4">{msg}</div>}
                                                </ErrorMessage>
                                                <div className="tw-text-center tw-p-3">Didn’t receive any code? <span onClick={() => { }} className="tw-cursor-pointer text-secondary">Resend OTP</span></div>
                                                <button
                                                    type="submit"
                                                    className="tw-w-full tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded tw-hover:bg-blue-600 tw-focus:outline-none"
                                                >
                                                    Verify OTP
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                )
                            }
                            <div>
                                <p className="tw-text-center text-primary tw-mt-8">{loginState === LOGIN_STATES.LOGIN ? (<>Don’t have an account? <span onClick={() => setIsLoginState(LOGIN_STATES.REGISTER)} className="tw-cursor-pointer text-secondary">Register</span>
                                </>) : loginState === LOGIN_STATES.OTP_VERIFY ? (<p className="tw-mb-5">Having trouble with OTP? <span onClick={() => { }} className="tw-cursor-pointer text-secondary">Get Help</span></p>) : (<>Already have an account? <span onClick={() => setIsLoginState(LOGIN_STATES.LOGIN)} className="tw-cursor-pointer text-secondary">Login</span></>)}</p>
                                {loginState !== LOGIN_STATES.REGISTER ? (
                                    <div className="tw-text-center text-grey">
                                        <p className="tw-py-3">
                                            <span className="tw-mr-4 tw-cursor-pointer">
                                                Terms of Service
                                            </span>
                                            <span className="tw-mr-4 tw-cursor-pointer">
                                                Privacy Policy
                                            </span>
                                        </p>
                                        <p>©2024, Unifinders Education Pvt. Ltd.</p>
                                    </div>) : null}
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    );
}
