import { ChevronLeft } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react'
import ReactPaginate from 'react-paginate';

function Pagination({ onPaginationChange,
    onRowsPerPageChange,
    totalItems,
    currentPage,
    isShowRowsPerPage,
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 15, 25] }) {

    let pageCount =
        totalItems % rowsPerPage === 0
            ? totalItems / rowsPerPage
            : Math.floor(totalItems / rowsPerPage) + 1;

    const handlePageClick = (event) => {
        onPaginationChange(event.selected);
    };

    const rowsPerPageChange = (e) => {
        onRowsPerPageChange(e.target.value);
    };

    return (
        <>
            <div className='pagination-head tw-flex tw-justify-between'>
                <div className="pagination-display">
                    <p>Displaying results : 1 to 8 of 156 entries</p>
                </div>
                <div className="pagination-container">
                {isShowRowsPerPage && (
                    <div className="customPagination-perPage">
                        <label htmlFor="">Items per page</label>
                        <select value={rowsPerPage} onChange={rowsPerPageChange}>
                            {rowsPerPageOptions.map((el) => (
                                <option key={el} value={el}>
                                    {el}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <ReactPaginate
                    previousLabel={(
                        <span className='tw-whitespace-nowrap'>
                        <ChevronLeft /> Previous
                        </span>
                    )}
                    forcePage={currentPage}
                    nextLabel={(
                        <span className='tw-whitespace-nowrap'>
                        Next <ChevronRightIcon /> 
                        </span>
                    )}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'cutom_pagination'}
                    previousLinkClassName={'customPagination__link'}
                    nextLinkClassName={'customPagination__link'}
                    disabledClassName={'customPagination__link--disabled'}
                    activeClassName={'customPagination__link--active'}
                />
            </div>
            </div>
        </>
    )
}

export default Pagination