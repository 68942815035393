import React from 'react'

function Select({options}) {
  return (
    <select className='custom-select'>
{options.map(item=> <option>{item.name}</option>)}
    </select>
  )
}

export default Select