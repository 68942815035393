import React from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { ArrowRightIcon, ChatBubbleLeftEllipsisIcon, EyeIcon } from '@heroicons/react/24/solid';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import { ThumbUpOutlined } from '@mui/icons-material';

function QueCard({ que }) {

    return (
        <div className='que-card tw-text-left tw-p-4 tw-mb-6'>
            <div className='tw-flex tw-justify-between'>
                <div className='tw-flex'>
                    <div><img src={'images/Avatar.png'} alt="" className="avatar" /></div>
                    <div className='tw-py-1.5 tw-px-3'>
                        <diV className='author'>Madelyn Torff</diV>
                        <p className='author-sub'>21 Jun, 2023</p>
                    </div>
                </div>
                <div className='tw-flex'>
                    <div className='available-answers text-secondary'>3 Answers Available</div>
                    <div className='tw-ml-4 tw-cursor-pointer'><ReportIcon style={{ color: '#E62631' }} /><span className='tw-ml-1'>Report</span></div>
                </div>
            </div>

            <div className='tw-my-4'>
                <div className='tw-mb-4'>{que}</div>
                <p className='que-description'>Hello, <br />Australia is a popular destination for international students who wish to study
                    engineering. Australia offers top-quality education across its top engineering colleges...
                </p>
                <p className='text-secondary tw-mt-1 tw-flex tw-cursor-pointer'>
                    Read More<span className='tw-ml-2 tw-mt-0.5'><ArrowRightIcon className=' tw-w-5 learn-more-arrow' /></span>
                </p>
            </div>
            <div className='tw-py-4 card-footer tw-flex tw-justify-between'>
                <div className='tw-flex tw-gap-2 tw-my-2.5'>
                    <div className='tw-flex tw-gap-1 tw-cursor-pointer tw-pr-4 tw-mr-4'>
                        <ThumbUpOutlined className='icons' />4565
                    </div>
                    <div className='tw-flex tw-gap-1 tw-cursor-pointer'>
                        <ChatBubbleLeftEllipsisIcon className='icons' />434
                    </div>
                    <div className='tw-flex tw-gap-1 tw-cursor-pointer tw-pl-4 tw-ml-4'>
                        <EyeIcon className='icons' />1321
                    </div>
                </div>
                <div className='tw-flex tw-gap-2'>
                    <SecondaryButton className='tw-py-2.5 tw-px-6'>Share</SecondaryButton>
                    <PrimaryButton className='tw-py-2.5 tw-px-6'>Answer</PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default QueCard;
