import React, { useState } from 'react'
import { blogsList } from '../../Constants/blogsList'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import Pagination from '../Pagination/Pagination';

const TABS = {
    ALL: 'ALL',
    COURSES: "COURSES",
    VISA_PROCESSING: "VISA_PROCESSING",
    STUDY_IN_UK: "STUDY_IN_UK",
    INSURANCE: "INSURANCE",
    EXPENSES: "EXPENSES",
    JOBS:"JOBS",
    STUDY_ABROAD: "STUDY_ABROAD"
  }

function Blogs() {
    const [activeTab, setActiveTab] = useState(TABS.ALL);
    const onRowsPerPageChange = ()=> {

    }
    const onPaginationChange = ()=> {

    }
  return (
    <div className='blogs'>
        <div className="blogs-tabs">
            <p className={activeTab == "ALL" ? "tw-text-white tw-bg-[#0072E3]" : ""}>All</p>
            <p>Courses</p>
            <p>Visa Processing</p>
            <p>Study In UK</p>
            <p>Insurance</p>
            <p>Expenses</p>
            <p>Jobs</p>
            <p>Study Abroad</p>
        </div>
        <div className="blogs-cards tw-grid tw-grid-cols-3 tw-gap-6">
            {blogsList.map((item)=> (
                <div className="blogs-cards_items" key={item.title}>
                <p><img src={item.imgUrl} alt="" /></p>
                <p>{item.date} <span>{item.location}</span></p>
                <p>{item.title}</p>
                <p>{item.description}</p>
                <p>Learn More <ArrowRightIcon className='tw-h-5 tw-w-5 learn-more-arrow' /></p>
            </div>
            ))}
        </div>
        <div className="blogs-pagination">
          <Pagination onPaginationChange = {onPaginationChange} onRowsPerPageChange={onRowsPerPageChange} totalItems={50} currentPage = {1} isShowRowsPerPage={true} />
        </div>
    </div>
  )
}

export default Blogs