import React from 'react'
import fb from '../../Assets/Images/icons/fb.png'
import linkedin from '../../Assets/Images/icons/linkedin.png'
import twitter from '../../Assets/Images/icons/twitter.png'
import { LocationOn, Timer } from '@mui/icons-material'
import insta from '../../Assets/Images/icons/insta.png'
import share from '../../Assets/Images/icons/share.png'
import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import location from '../../Assets/Images/location.png'
import calendarIcon from '../../Assets/Images/icons/calendar.png'
import { eventsList } from '../../Constants/eventsList'
import locationIcon from '../../Assets/Images/icons/location.png'
import SecondaryButton from '../Buttons/SecondaryButton'
import PrimaryButton from '../Buttons/PrimaryButton'
import { useNavigate } from 'react-router'

function EventsPage({ event }) {
    const universities = [...Array(15).keys()].map(i => ({ img: `/images/universities/Article-${i}.png`, name: '', id: i }));
    const navigate = useNavigate();

    return (
        <div className=''>
            <div className='tw-py-12 tw-border-b-2'>
                <div className='tw-flex tw-justify-between'>
                    <h1 className='heading1 bold'>{event.title}</h1>
                    <div className='tw-flex tw-space-x-2 tw-max-h-8'>
                        <span className='tw-mt-1 bold'>Share:</span>
                        <img src={fb} alt='fb' />
                        <img src={linkedin} alt='linkedin' />
                        <img src={twitter} alt='twitter' />
                        <img src={insta} alt='insta' />
                        <img src={share} alt='share' />
                    </div>
                </div>
                <div className='tw-py-10'>
                    <img className='tw-rounded-xl tw-w-full' src={event.imgUrl} alt={event.title} />
                </div>
                <div className='tw-flex tw-justify-between'>
                    <div className='tw-flex tw-gap-9'>
                        <div className='tw-flex'>
                            <div className='tw-p-2'>
                                <img src={calendarIcon} alt="calendarIcon" />
                            </div>
                            <div>
                                <p className=''>Date & Time<br /><span className='bold'>15 Dec, 2024</span></p>
                            </div>
                        </div>
                        <div className='tw-flex'>
                            <div className='tw-p-2'>
                                <img src={locationIcon} alt="locationIcon" />
                            </div>
                            <div>
                                <p className=''>Location
                                    <br /><span className='bold'>Harmony Seminar Hall</span>
                                    <span className='tw-cursor-pointer tw-underline text-secondary tw-ml-2'>View map</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='tw-flex tw-gap-9'>
                        <SecondaryButton className='tw-py-4 tw-px-8 text-secondary'>Add to Calender</SecondaryButton>
                        <PrimaryButton className={'tw-py-4 tw-px-8'}>Register Now</PrimaryButton>
                    </div>
                </div>
            </div>
            <div className='tw-py-12 tw-grid tw-grid-cols-2 tablet:tw-grid-cols-1 tw-gap-48'>
                <div className=''>
                    <h1 className='heading1 bold tw-pb-6'>Description</h1>
                    <p className='tw-whitespace-pre-wrap'>{event.descriptionFull}</p>
                </div>
                <div className=''>
                    <h1 className='heading1 bold tw-pb-6'>Event Location</h1>

                    <img className='tw-rounded-xl tw-w-full' src={location} alt={'location'} />
                    <div className='tw-my-10'>
                        <h1 className="heading1 bold">Harmony Seminar Hall</h1>
                        <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
                        <div className='tw-flex tw-nowrap tw-gap-2 tw-cursor-pointer tw-mt-2'> <span>View Map</span>
                            <ArrowRightIcon className='tw-h-6 tw-w-6' />
                        </div>
                    </div>
                    <div className='tw-mb-3'>
                        <h1 className="heading1 bold tw-py-8">Tags</h1>
                        <div className="bubble-text">
                            <p>Courses</p>
                            <p>Visa Processing</p>
                            <p>Study In UK</p>
                            <p>Insurance</p>
                            <p>Expenses</p>
                            <p>Jobs</p>
                            <p>Study Abroad</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tw-my-5'>
                <img className='tw-w-full' src="/images/ads.png" alt="ads" />
            </div>

            <div className='tw-my-8 tw-py-12 tw-border-b-2'>
                <h1 className='heading1 tw-text-center bold'>Thank you to our sponsors </h1>

                <div className='universities tw-p-10 tw-min-h-[440px]'>
                    <ul className='tw-grid tw-grid-cols-5'>
                        {universities.map((university, i) => <li key={i} className='tw-cursor-pointer'>
                            <img src={university.img} alt={university.name} />
                        </li>)}
                    </ul>
                </div>
            </div>
            <div className='tw-my-8 tw-py-12 tw-border-b-2'>
                <h1 className='heading1 tw-text-center bold'>Got Question? <span className='text-secondary'>Find answers here.</span> </h1>
                <ul className='tw-py-7'>
                    <li className='bold tw-cursor-pointer tw-py-6 tw-border-b-[1px] tw-flex tw-justify-between'>
                        <div>What is Unifinder’s Scholarship?</div>
                        <div className='text-secondary tw-text-2xl bold tw-m-0'>+</div>
                    </li>
                    <li className='bold tw-cursor-pointer tw-py-6 tw-border-b-[1px] tw-flex tw-justify-between'>
                        <div>What is Unifinder’s Scholarship?</div>
                        <div className='text-secondary tw-text-2xl bold tw-m-0'>+</div>
                    </li>
                    <li className='bold tw-cursor-pointer tw-py-6 tw-border-b-[1px] tw-flex tw-justify-between'>
                        <div>What is Unifinder’s Scholarship?</div>
                        <div className='text-secondary tw-text-2xl bold tw-m-0'>+</div>
                    </li>
                    <li className='bold tw-cursor-pointer tw-py-6 tw-border-b-[1px] tw-flex tw-justify-between'>
                        <div>What is Unifinder’s Scholarship?</div>
                        <div className='text-secondary tw-text-2xl bold tw-m-0'>+</div>
                    </li>
                    <li className='bold tw-cursor-pointer tw-py-6 tw-border-b-[1px] tw-flex tw-justify-between'>
                        <div>What is Unifinder’s Scholarship?</div>
                        <div className='text-secondary tw-text-2xl bold tw-m-0'>+</div>
                    </li>
                </ul>
            </div>

            <div className='tw-py-12'>
                <div className='tw-flex tw-justify-between bold'>
                    <h1 className='heading1'>Other events you may like</h1>
                    <div className='tw-flex tw-gap-8'>
                        <SecondaryButton className='text-secondary tw-p-3'><ChevronLeftIcon className='tw-h-6 tw-w-6 bold' /></SecondaryButton>
                        <SecondaryButton className='text-secondary tw-p-3'><ChevronRightIcon className='tw-h-6 tw-w-6 bold' /></SecondaryButton>
                    </div>
                </div>
                <div className="events-cards tw-grid tw-grid-cols-3 tw-gap-6">
                    {eventsList.slice(2, 5).map((item) => {
                        let concatedDateOne = item.date.slice(0, 2)
                        let concatedDateTwo = item.date.slice(3, 6)

                        return (
                            <div onClick={() => navigate(`/events/${item.id}`)} className="events-cards_items tw-cursor-pointer" key={item.title}>
                                <p><img src={item.imgUrl} /> <span className='broke-date'> <span>{concatedDateOne}</span><span>{concatedDateTwo}</span> </span></p>
                                <p>{item.title}</p>
                                <p>{item.description}</p>
                                <p><Timer /> {item.time}</p>
                                <p><LocationOn /> {item.location}</p>
                                <p>Learn More <ArrowRightIcon className='tw-h-5 tw-w-5 learn-more-arrow' /></p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default EventsPage