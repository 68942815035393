import React from 'react';
import ClientImg from '../../Assets/Images/client.png';
import DQuoteImg from '../../Assets/Images/“.png';

function SuccessStories() {

    return (
        <div className='wrapper tw-mt-10 success-stories'>
            <div className='connecting-container'>
                <p className='center text-secondary'>SUCCESS STORIES</p>
                <h1 className='heading1 center bold'>
                    What our <span className='text-secondary '>Clients Say</span>
                </h1>
                <div className='clients-say tw-my-9 tw-grid tw-grid-cols-3 tablet:tw-flex tablet:tw-flex-col tablet:tw-h-fit tablet:tw-w-fit'>
                    <div>
                        <img src={ClientImg} alt='client' />
                    </div>
                    <div className='tw-col-span-2 tw-p-14 tablet:tw-px-1'>
                        <img src={DQuoteImg} alt='"' />
                        <p className='tw-my-8'>
                            Lorem ipsum dolor sit amet consectetur. Quis felis urna viverra eget leo purus iaculis. Vestibulum in mattis ut mi risus porta. In tincidunt ac neque amet nibh accumsan urna aliquet. Dolor hendrerit facilisi fames non elit elementum hac.
                        </p>
                        <h3 className='heading3 tw-pt-7'>
                            Tatiana Philips
                        </h3>
                        <p>United States</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SuccessStories;
