import React, { useEffect, useRef, useState } from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton'
import { ChevronDownIcon, GlobeAltIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import unifindersLogo from '../../Assets/Images/unifinderslogo.png'
import persuitsImg from '../../Assets/Images/purcuitsImg.jpeg'
import { MenuList } from '../../Constants/Menulist'
import { coursesList } from '../../Constants/courses_list'
import { Link, useNavigate } from 'react-router-dom'
import BrowseByCountry from '../BrowseByCounty/BrowseByCountry'

function Heading() {
  const navigate = useNavigate();

  const ourCourseElRef = useRef(null)
  const showBrowseByCountyRef = useRef(null)
  const [showCourses, setShowCourses] = useState(false)
  const [showBrowseByCounty, setShowBrowseByCounty] = useState(false)

  const handleMenuHover = (name) => {
    if (name === "Our Courses") {
      setShowBrowseByCounty(false)
      setShowCourses(true)
    } else {
      setShowCourses(false)
    }

  }

  useEffect(() => {
    const accessTokenRegex = /token=([^&]+)/;
    const isMatch = window.location.href.match(accessTokenRegex);

    if (isMatch) {
        const accessToken = isMatch[1];
        localStorage.setItem("access_token", accessToken);
        // setIsLoggedin(true);
    }
}, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ourCourseElRef.current && !ourCourseElRef.current.contains(event.target)) {
        if (
          !event.target.classList.contains("our-sources") &&
          !event.target.classList.contains("menu-item") && !event.target.classList.contains("dummy-class")
        ) {
          setShowCourses(false)

        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (showBrowseByCountyRef.current && !showBrowseByCountyRef.current.contains(event.target)) {
        if (
          !event.target.classList.contains("browse-by-country-container") &&
          !event.target.classList.contains("secondary-button") && !event.target.classList.contains("dummy-class") && !event.target.classList.contains("closeMe")
        ) {

          setShowBrowseByCounty(false)
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);

  useEffect(() => {
    if (showCourses || showBrowseByCounty) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

  }, [showCourses, showBrowseByCounty])

  const handleBrowseByCounty = () => {
    setShowBrowseByCounty(!showBrowseByCounty)
  }

  return (
    <div className='headingtop'>
      <div className='headingtop-logo'>
        <Link to="/">  <p><img src={unifindersLogo} /></p></Link>
        <SecondaryButton handleClick={handleBrowseByCounty}>
          <div className='tw-flex tw-flex-nowrap tw-gap-2 dummy-class tw-text-sm'>
            <GlobeAltIcon className='tw-h-6 tw-w-6 dummy-class' /> Brouwse By Country
            <span className={`${showBrowseByCounty ? 'close-showBrowse-by-country active closeMe' : 'close-showBrowse-by-country closeMe'}`}>
              <XMarkIcon className='closeMe tw-h-5 tw-w-5 tw-text-white' />
            </span>
          </div>
        </SecondaryButton>

      </div>
      <div className='headingtop-navigation'>
        <div className='headingtop-navigation-menu tw-flex'>
          {MenuList?.map((item) => {
            if (item.name === "Our Courses") {
              return <div key={item.name} className={`menu-item ${showCourses && 'active'} tw-flex tw-whitespace-nowrap `} onMouseEnter={() => handleMenuHover(item.name)} >
                <p className='dummy-class tw-text-[#091E42] tw-text-sm'>{item.name}</p>
                <p className='dummy-class'><ChevronDownIcon className='dummy-class tw-h-6 tw-w-5 tw-ml-1' /></p>
              </div>
            } else {
              return <Link to={item.reUrl}><div key={item.name} className='menu-item tw-text-sm' onMouseEnter={() => handleMenuHover(item.name)}>{item.name}</div></Link>
            }
          })}
        </div>
        <div className='headingtop-navigation-login tw-flex tw-ml-12'>
          <SecondaryButton ><span className='tw-text-sm'>Book an Appointment</span> </SecondaryButton>
          <div className='tw-ml-4 tw-flex tw-whitespace-nowrap'>
            <PrimaryButton handleClick={() => navigate('/login')}><div className='tw-flex tw-nowrap tw-gap-2'>
              <UserCircleIcon className='tw-h-6 tw-w-6' /> <span className='tw-text-sm'>Log In</span></div>
            </PrimaryButton> </div>
        </div>
      </div>
      <div className={`${showCourses ? 'headingtop-courses-active' : 'headingtop-courses'}`} >
        <div className='our-sources' ref={ourCourseElRef}>
          <div className='our-sources-courses tw-grid tw-grid-cols-2'>
            {coursesList.map((item) => <div key={item.title} className='headtop-card'>
              <div className='tw-h-16 tw-w-32'><img src={item.logo} alt="" className='tw-h-16 tw-w-32' /></div>
              <div className=''>
                <p className='tw-flex tw-items-center tw-gap-2 tw-text-xl tw-font-bold'>{item.title} {item.isNew && <span className='tw-p-1 tw-bg-[#0072e3] tw-text-xs tw-text-white tw-rounded-md'>New!</span>}</p>
                <p>{item.description}</p>
              </div>
            </div>)}
          </div>
          <div className='our-sources-persuits'><img src={persuitsImg} alt="" /></div>
        </div>
      </div>
      <div className={`${showBrowseByCounty ? 'browse-by-country active' : 'browse-by-country'}`} >
        <div className='browse-by-country-container' ref={showBrowseByCountyRef}>
          <BrowseByCountry />
        </div>
      </div>
    </div>
  )
}

export default Heading