import React, { useEffect, useState } from 'react'
import unifindersLogo from '../../Assets/Images/unifinderslogo.png';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { FacebookOutlined, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { ArrowUpIcon } from '@heroicons/react/24/solid';


function Footer() {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      setTimeout(() => {
        setClicked(false);
      }, 1000);
    }
  }, [clicked]);

  const handleBackToTop = ()=> {
    setClicked(true);
  }
  return (
<>
<div className='footer'>
        <div className="footer-top">
            <div className="footer-top_logo">
                <p><img src={unifindersLogo} alt="" /></p>
                <p>Get End-to-end support with our experts</p>
            </div>
            <div className="footer-top_media">
                <p>Connect with Us</p>
                <p><span><FacebookOutlined /></span> <span><Instagram /></span> <span><LinkedIn /></span> <span><Twitter /></span></p>
            </div>
        </div>
        <hr />
        <div className="footer-contact">
          <div className="footer-contact_location">
            <p><FmdGoodIcon className='tw-text-[#0072E3]' /> Location</p>
            <p>New Plaza Putalisadak, Opposite to Vibrant,</p>
            <p>Kathmande, Nepal</p>
          </div>
          <div className="footer-contact_contact">
            <p><PhoneIcon className='tw-text-[#0072E3]' /> Contact Us</p>
            <p>Call/Whatsapp : 980-1132288</p>
            <p>Tel No : 01-5901332 | 01-5901334 </p>
          </div>
          <div className="footer-contact_email">
            <p><EmailIcon className='tw-text-[#0072E3]' /> Email Us</p>
            <p>info@myunifinders.com</p>
          </div>
        </div>
        <hr />
        <div className="footer-links">
          <div>
            <p>About Unifinders</p>
            <p>About Us</p>
            <p>Success Stories</p>
            <p>Student</p>
            <p>Recruiter</p>
            <p>Institution</p>
          </div>
          <div>
            <p>Study Abroad</p>
            <p>Study in Australia</p>
            <p>Study in USA</p>
            <p>Study in UK</p>
            <p>Study in Canada</p>
            <p>Study in New Zealand</p>
            <p>Study in New Ireland</p>
            <p>Study in New India</p>
          </div>
          <div>
            <p>Features</p>
            <p>Careers</p>
            <p>Blog</p>
            <p>News and Events</p>
            <p>In Media</p>
            <p>QnA</p>
          </div>
          <div>
            <p>Legal</p>
            <p>Terms of Services</p>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
          </div>
        </div>

    </div>
    <div className="footer-copyright">
          <p>Copyright &#169;2024, Unifinders Education Pvt.Ltd. All Rights Reserved.</p>
          <div><button onClick={handleBackToTop}>Back to Top <ArrowUpIcon className='tw-h-6 tw-w-6' /></button></div>
        </div>
</>
  )
}

export default Footer