import GMATLOGO from '../Assets/Images/GMAT.png'
import GRELOGO from '../Assets/Images/GRE.png'
import IELTSOGO from '../Assets/Images/IELTS.png'
import OTEOGO from '../Assets/Images/OET.png'
import SATOGO from '../Assets/Images/SAT.png'
import PTE from '../Assets/Images/PTE.png'
import TOFELLOGO from '../Assets/Images/TOFEL.png'
export const coursesList = [
    {
        name: "IELTS",
        logo: IELTSOGO,
        title: "IELTS",
        description: "Internationl English Language Testing System",
        redirectUrl: "/home",
        isNew: true
    },
    {
        name: "PTE",
        logo: PTE,
        title: "PTE",
        description: "Person test English",
        redirectUrl: "/home",
        isNew: true
    },
    {
        name: "TOFEL",
        logo: TOFELLOGO,
        title: "TOFEL",
        description: "Test of English as a Foreign Language",
        redirectUrl: "/home",
        isNew: false
    },
    {
        name: "GMAT",
        logo: GMATLOGO,
        title: "GMAT",
        description: "Graduate Management Adminssion Test",
        redirectUrl: "/home",
        isNew: false
    },
    {
        name: "GRE",
        logo: GRELOGO,
        title: "GRE",
        description: "Graduate Record Examination",
        redirectUrl: "/home",
        isNew: false
    },
    {
        name: "SAT",
        logo: SATOGO,
        title: "SAT",
        description: "Scholastic Assessment Test",
        redirectUrl: "/home",
        isNew: true
    },
    {
        name: "OET",
        logo: OTEOGO,
        title: "OET",
        description: "Occupational English Test",
        redirectUrl: "/home",
        isNew: true
    },
]