import React from 'react'
import Heading from '../Components/Heading/Heading'
import FilterSearch from '../Components/Scholarships/FilterSearch'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import EventsPage from '../Components/Events/EventsPage'
import Pagination from '../Components/Pagination/Pagination'
import Footer from '../Components/Footer/Footer'
import StudingAbroadBanner from '../Components/StudingAbroadBanner/StudingAbroadBanner'
import girlImg from '../Assets/Images/Events/wepik-export-20230814102810Li1u.png'

function Events() {

  const onRowsPerPageChange = ()=> {

  }
  const onPaginationChange = ()=> {

  }
  return (
    <>
    <Heading />
    <div className='events'>
        <div className='breadboard'>
          <p className='tw-text-[16px]'>Home</p>
          <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
          <p className='tw-text-[18px] tw-text-[#2b8ae7]'>Events</p>
        </div>
        <div>
          <FilterSearch />
        </div>
        <div className="events-cards">
            <EventsPage />
        </div>

        </div>
        <div className="scholarship-pagination">
          <Pagination onPaginationChange = {onPaginationChange} onRowsPerPageChange={onRowsPerPageChange} totalItems={50} currentPage = {1} />
        </div>
        <div>
          <StudingAbroadBanner img={girlImg} />
        </div>
        <Footer />
    </>
  )
}

export default Events