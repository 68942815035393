import React from 'react'
import Heading from '../Components/Heading/Heading'
import { AcademicCapIcon, CalendarIcon, ChevronRightIcon, CurrencyDollarIcon, HeartIcon, HomeModernIcon } from '@heroicons/react/24/solid'
import FilterSearch from '../Components/Scholarships/FilterSearch'
import Select from '../Components/InputField/Select'
import { programList } from '../Constants/ProgramList'
import Footer from '../Components/Footer/Footer'
import Pagination from '../Components/Pagination/Pagination'
import StudingAbroadBanner from '../Components/StudingAbroadBanner/StudingAbroadBanner'
import imgGirl from '../Assets/Images/Events/wepik-export-20230811074638IC3g 1.png'

function Scholarships() {

  const onRowsPerPageChange = ()=> {

  }
  const onPaginationChange = ()=> {

  }

  return (
    <>
      <Heading />
      <div className='scholarship'>
        <div className='breadboard'>
          <p className='tw-text-[16px]'>Home</p>
          <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
          <p className='tw-text-[18px] tw-text-[#2b8ae7]'>Scholarship</p>
        </div>
        <div>
          <FilterSearch />
        </div>
        <div className='scholarship-allprograms'>
          <div className='tw-flex tw-justify-between'>
            <p className='tw-text-2xl tw-text-[#091E42]'>All Programs</p>
            <div>
              <div>
                <label htmlFor="">Sort By : </label>
                <Select options={[{ name: "Most Popular" }, { name: "Less Popular" }]} />
              </div>
            </div>
          </div>
          <div className='program-card-container tw-grid tw-grid-cols-3 tw-gap-6'>
            {programList.map((item)=> {
              return (
                <div className="program-card-container-item">
                <div className='program-card-container-item-img'>
                  <img src={item.imgUrl} alt="" />
                  <p><HeartIcon className='tw-h-6 tw-w-6' /></p>
                </div>
                <p>{item.cardHeading}</p>
                <div className='program-card-container-item_details'>

  
                  <div><p className='tw-flex tw-gap-4'><span><HomeModernIcon className='tw-h-6 tw-w-6 tw-text-[#0072E3]'  /></span>Institue</p>
                  <p>{item.institue}</p></div>
                  <div><p className='tw-flex tw-gap-4'><span><CurrencyDollarIcon className='tw-h-6 tw-w-6 tw-text-[#0072E3]'  /></span>1st year tution fee</p>
                  <p>{item.tutionFee} <span className='tw-text-[#6b788e]'>(USD)</span></p></div>
                  <div><p className='tw-flex tw-gap-4'><span><CalendarIcon className='tw-h-6 tw-w-6 tw-text-[#0072E3]'  /></span>Intake</p>
                  <p>{item.intake} <span className='tw-bg-[#0072E3] tw-text-[12px] tw-p-1 tw-rounded-xl tw-text-white'>{item.intakeSeason}</span></p></div>
                  <div><p className='tw-flex tw-gap-4'><span><AcademicCapIcon className='tw-h-6 tw-w-6 tw-text-[#0072E3]'  /></span>Lavel of Study</p>
                  <p>{item.lavelOfStudy}</p></div>
                </div>
              </div>
              )
            })}
          
          </div>
        </div>
      </div>
      <div className="scholarship-pagination">
          <Pagination onPaginationChange = {onPaginationChange} onRowsPerPageChange={onRowsPerPageChange} totalItems={50} currentPage = {1} />
        </div>
        <div>
          <StudingAbroadBanner img={imgGirl} />
        </div>
      <Footer />
    </>

  )
}

export default Scholarships