import React from 'react'
import { useParams } from 'react-router';

import Heading from '../Components/Heading/Heading'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import EventDetail from '../Components/Events/EventDetail'
import Footer from '../Components/Footer/Footer'
import { eventsList } from '../Constants/eventsList'

function Event() {
    const { id } = useParams();

    const event = eventsList.find(event => event.id === id);

    return (
        <>
            <Heading />
            <div className='event-detail'>
                <div className='breadboard'>
                    <p className='tw-text-[16px]'>Home</p>
                    <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
                    <p className='tw-text-[16px]'>Events</p>
                    <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
                    <p className='tw-text-[18px] tw-text-[#2b8ae7]'>{event.title}</p>
                </div>
                <EventDetail event={event} />
            </div>
            <Footer />
        </>
    )
}

export default Event;
