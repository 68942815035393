import React, { useState } from 'react';
import { AcademicCapIcon, ArrowRightIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/24/solid';
import personalizeCounsellingImg from '../../Assets/Images/personalizecounselling.png'
import adviceIcon from '../../Assets/Images/icons/advice.png'
import universityIcon from '../../Assets/Images/icons/university.png'
import reportIcon from '../../Assets/Images/icons/report.png'
import universityExploreImg from '../../Assets/Images/University Explore.png'
import ReportCardImg from '../../Assets/Images/Report Card.png'
import globalMapImg from '../../Assets/Images/map.png'
import PrimaryButton from '../Buttons/PrimaryButton';


const TABS = {
    STUDENT: 'STUDENT',
    WHO_WE_ARE: 'WHO_WE_ARE',
    UNIVERSITY: 'UNIVERSITY'
}

function ConnectingUniversities() {
    const [activeTab, setActiveTab] = useState(TABS.STUDENT);

    return (
        <div className='wrapper center connecting-universities'>
            <div className='connecting-container'>
                <h1 className='heading1 center'>
                    Connecting <span className='text-secondary bold'>Universities</span>
                </h1>
                <h1 className='heading1 center'>
                    With <span className='text-secondary bold'>Students</span>
                </h1>
                <p className='tw-mx-[18%]'>Unifinders works hand-in-hand for partner institutions. We support marketing, brand building, student diversity and international students recruitment. </p>
            </div>
            <div className='connecting-container'>
                <ul className='tabs tw-w-fit'>
                    <li onClick={() => setActiveTab(TABS.STUDENT)} className={activeTab === TABS.STUDENT ? 'active' : ''}><UsersIcon className='tw-h-7 tw-w-8 tw-ml-1' /> Student </li>
                    <li onClick={() => setActiveTab(TABS.WHO_WE_ARE)} className={activeTab === TABS.WHO_WE_ARE ? 'active' : ''}><UserGroupIcon className='tw-h-7 tw-w-8 tw-ml-1' /> Who we are </li>
                    <li onClick={() => setActiveTab(TABS.UNIVERSITY)} className={activeTab === TABS.UNIVERSITY ? 'active' : ''}><AcademicCapIcon className='tw-h-7 tw-w-8 tw-ml-1' /> University </li>
                </ul>
            </div>
            <div className=''>
                {activeTab === TABS.STUDENT ? (<h1>Student Section</h1>) : activeTab === TABS.WHO_WE_ARE ? (<h1>Who We Are Section</h1>) : (<h1>University Section</h1>)}
            </div>
            <div>
                <div className='connecting-card tw-grid tw-gap-14 tw-w-full tw-pt-10 tw-pb-10'>
                    <div className='img-card tw-pr-16'>
                        <img src={personalizeCounsellingImg} alt="Personalize Counselling" /></div>
                    <div className='text-card tw-pl-24'>
                        <img src={adviceIcon} alt='Advice' />
                        <h1 className='heading1 tw-mt-4 tw-max-w-md'><span className='bold'>Get Personalized Counselling</span> from our experts</h1>
                        <p className='tw-mb-10'>Our experts assist you with country guide, course & university selection and admission with hustle free visa processing.</p>
                        <PrimaryButton className='tw-p-4 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span>Book an Appointment</span>
                                <ArrowRightIcon className='tw-h-6 tw-w-6' />
                            </div>
                        </PrimaryButton>
                    </div>
                </div>

                <div className='connecting-card tw-grid tw-gap-14 tw-w-full tw-pt-10 tw-pb-10'>
                    <div className='text-card tw-pr-48'>
                        <img src={universityIcon} alt='University' />
                        <h1 className='heading1 tw-mt-4 tw-max-w-xl'><span className='bold'>Explore your Course and Universities</span> globally with us</h1>
                        <p className='tw-mb-10'>At unifinders, search and compare courses & universities where you get best fit into.</p>
                        <PrimaryButton className='tw-p-4 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span>Explore destination and courses</span>
                                <ArrowRightIcon className='tw-h-6 tw-w-6' />
                            </div>
                        </PrimaryButton>
                    </div>
                    <div className='img-card tw-pr-16'>
                        <img src={universityExploreImg} alt="Explore University" />
                    </div>
                </div>
                <div className='connecting-card tw-grid tw-gap-14 tw-w-full tw-pt-10 tw-pb-10'>
                    <div className='img-card tw-pr-16'>
                        <img src={ReportCardImg} alt="Report Card" /></div>
                    <div className='text-card tw-pl-24'>
                        <img src={reportIcon} alt='Report' />
                        <h1 className='heading1 tw-mt-4 tw-max-w-md'>Keep a track of your <span className='bold'>Admission & Visa</span></h1>
                        <p className='tw-mb-10'>The easiest way to apply and get enrolled at international universities. Get in real-time and stay updated regarding your progress.</p>
                        <PrimaryButton className='tw-p-4 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span>Go to Student Dashboard</span>
                                <ArrowRightIcon className='tw-h-6 tw-w-6' />
                            </div>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
            <div className='tw-pt-10 tw-w-full'>
                <h1 className='heading1'>
                    Connecting <span className='text-secondary bold'>School, Universities,</span> and
                </h1>
                <h1 className='heading1'>
                    <span className='text-secondary bold'>Students </span>worldwide
                </h1>
                <p className='tw-text-left'>Our app connects to all the most popular universities. <br />Your academic applications are validated by our team and send to universities. </p>
                <div className='students-world-wide tw-grid tw-my-12 tw-gap-6'>
                    <div>

                        <div className='tw-p-6'>
                            <h1 className='heading1 text-secondary bold'>100+</h1>
                            <div className='tw-text-left tw-mt-1'>Recruitment Partners</div>
                        </div>
                        <div className='tw-p-6'>
                            <h1 className='heading1 text-secondary bold'>120+</h1>
                            <div className='tw-text-left tw-mt-1'>Universities Worldwide</div>
                        </div>
                        <div className='tw-p-6'>
                            <h1 className='heading1 text-secondary bold'>130+</h1>
                            <div className='tw-text-left tw-mt-1'>Countries</div>
                        </div>
                    </div>
                    <div>

                        <div className='tw-p-6'>
                            <h1 className='heading1 text-secondary bold'>1K+</h1>
                            <div className='tw-text-left tw-mt-1'>Courses</div>
                        </div>
                        <div className='tw-p-6'>
                            <h1 className='heading1 text-secondary bold'>12K+</h1>
                            <div className='tw-text-left tw-mt-1'>Courses</div>
                        </div>
                    </div>
                    <div className='tw-col-span-2'>
                        <img src={globalMapImg} alt='Global Map' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectingUniversities;
