import React from 'react'
import { Route, Routes } from "react-router-dom";
import Scholarships from '../Pages/Scholarships';
import App from '../App';
import Blog from '../Pages/Blog';
import QnA from '../Pages/QnA';
import Events from '../Pages/Events';
import EventDetail from '../Pages/EventDetail';
import Login from '../Components/Login/Login';

function PageRoutes() {
  return (
    <Routes>
        <Route path="/" exact element={<App />} />
        <Route path="/scholarships" exact element={<Scholarships />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/q-n-a" exact element={<QnA />} />
        <Route path="/events/:id" exact element={<EventDetail />} />
        <Route path="/events" exact element={<Events />} />

  </Routes>
  )
}

export default PageRoutes