import blogImg1 from '../Assets/Images/blogs/Image.png'
import blogImg2 from '../Assets/Images/blogs/Image 2.png'
import blogImg3 from '../Assets/Images/blogs/Image 3.png'
export const blogsList = [
    {
        imgUrl:blogImg1,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
    {
        imgUrl:blogImg2,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
    {
        imgUrl:blogImg3,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
    {
        imgUrl:blogImg1,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
    {
        imgUrl:blogImg2,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
    {
        imgUrl:blogImg3,
        date: "Dec 20, 2023",
        location: "Aspen Botosh",
        title: "Test Preparation for GRE",
        description: "Lorem ipsom dolor si amet consecterur, Justo mauris nbh bitae vestibulum quris. ",
        
    },
]