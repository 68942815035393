import React from 'react';
import ClockImg from '../../Assets/Images/icons/clock.png'
import LocationImg from '../../Assets/Images/icons/location.png'
import { ArrowRightIcon } from '@heroicons/react/24/solid';

function EventCard({ image }) {

    return (
        <div className='event-card tw-text-left'>
            <div className='date tw-p-4 tw-flex tw-flex-col'>
                <span className='bold day'>15</span>
                <span className='month'>Dec</span>
            </div>
            <img src={image} alt={'name'} />
            <div className='tw-p-6'>
                <div className='bold text-primary'>Test Preparation for GRE</div>
                <p className='tw-py-2'>Our app connects to all the most popular universities...</p>
                <div className='tw-py-3'>
                    <p className='text-primary tw-flex tw-gap-2 '><img className='tw-h-6' src={ClockImg} alt={'Clock'} />
                        12:00 to 3:00
                    </p>
                    <p className='text-primary tw-flex tw-gap-2 tw-pt-2'><img className='tw-h-6' src={LocationImg} alt={'Clock'} />
                        Harmony Seminar Hall
                    </p>
                </div>
                <p className='text-primary tw-flex tw-gap-3 tw-cursor-pointer'>Learn More <span><ArrowRightIcon className='tw-w-5' /></span></p>
            </div>
        </div>
    )
}

export default EventCard;
