import React from 'react'
import bgImg from '../../Assets/Images/blueHeroimg.png'
import CA from '../../Assets/Images/CA.png'
import NZ from '../../Assets/Images/197589.png'
import US from '../../Assets/Images/US.png'
import UK from '../../Assets/Images/UK.png'
import { HomeModernIcon } from '@heroicons/react/24/solid'

function StudingAbroadBanner({ img }) {
    return (
        <div className='banner-container'>
            <img src={bgImg} alt="" />
            <div className='tw-flex tw-justify-between tw-w-full'>
                <div className='banner-container_details'>
                    <h1>Are you interested in</h1>
                    <h1>Studying Abroad ?</h1>
                    <p>We’ll help you find the perfect program, apply and start planning your adventure!</p>
                    <button>Get Started</button>
                </div>
                <div className='banner-container_img'>
                    <div className="banner-hero-img">
                        <div className='banner-hero-img-container'>
                            <img src={img} alt="Girl" />
                            <p > <span>
                                <div className='hero-universites'>
                                    <p><HomeModernIcon className='tw-h-8 tw-w-8 tw-text-[#96c5f4]' /></p>
                                    <div>
                                        <p>120+</p>
                                        <p className='tw-whitespace-nowrap'>Universities Worldwide</p>
                                    </div>
                                </div>
                                <div className='hero-canada-img'>
                                    <img src={CA} alt="" className='tw-h-9' />
                                </div>
                                <div className='hero-us-img'>
                                    <img src={US} alt="" className='tw-h-8' />
                                </div>
                                <div className='hero-uk-img'>
                                    <img src={UK} alt="" className='tw-h-8' />
                                </div>
                                <div className='hero-australia-img'>
                                    <img src={NZ} alt="" className='tw-h-8' />
                                </div>
                            </span></p>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StudingAbroadBanner