import React from 'react'
import Heading from '../Components/Heading/Heading'
import heroGirl from '../Assets/Images/hero-girl.png'
import frame from '../Assets/Images/Frame.png'
import CA from '../Assets/Images/CA.png'
import NZ from '../Assets/Images/197589.png'
import US from '../Assets/Images/US.png'
import UK from '../Assets/Images/UK.png'
import { CheckCircleIcon, HomeModernIcon } from '@heroicons/react/24/solid'
import ConnectingUniversities from '../Components/ConnectingUniversities/ConnectingUniversities'
import GetScholarships from '../Components/GetScholarships/GetScholarships'
import VisaCalculator from '../Components/VisaCalculator/VisaCalculator'
import Footer from '../Components/Footer/Footer'
import OurPartnerUniversities from '../Components/OurPartnerUniversities/OurPartnerUniversities'
import OurEvents from '../Components/OurEvents/OurEvents'
import OurBlogs from '../Components/OurBlogs/OurBlogs'
import SuccessStories from '../Components/SuccessStories/SuccessStories'
import StudingAbroadBanner from '../Components/StudingAbroadBanner/StudingAbroadBanner'
import girlImg from '../Assets/Images/wepik-export-20230811074638IC3g 1.png'
import bgImg from '../Assets/Images/blueHeroimg.png'
import vectorLine from '../Assets/Images/Vector (1).png'
import clientImg from '../Assets/Images/20e2badd8eb457dc0e2093534003a2ad.jpeg'
import clientImg2 from '../Assets/Images/28ca5dd46c0435aca688e25cfb47c67b.jpeg'
import clientImg3 from '../Assets/Images/312f667f98eac44d4144561466c88743.jpeg'

function HeroSection() {
  return (
    <div>
      <Heading />
      <div className='container'>
        <img src={bgImg} alt="Background Image" />
        <div className='hero'>
          <div className="hero-container">
            <p>STUDY ABROAD</p>
            <p>Explore <span>Globally</span></p>
            <p>at your <span className='fingertips'>fingertips <span><img src={vectorLine} alt="" /></span></span></p>
            <p className='tw-flex tw-whitespace-nowrap'><CheckCircleIcon className='tw-h-8 tw-w-8 tw-mr-1 tw-text-[#0072E3]' /> Top global colleges across the globe</p>
            <p className='tw-flex tw-whitespace-nowrap'> <CheckCircleIcon className='tw-h-8 tw-w-8 tw-mr-1 tw-text-[#0072E3]' />Best counselling across 140+ universites worldwide</p>
            <div className='hero-search'>
              <input className='hero-search-courses' type="text" placeholder='Course or University' />
              <input className='hero-search-location' type="text" placeholder='Location' />
              <div className='hero-search-btn'><button ><p className='tw-text-white'>Search</p></button></div>
            </div>
            <div className='hero-trusted-clients'>

              <p><img src={clientImg2} alt="client" /></p>
              <p><img src={heroGirl} alt="client" /></p>
              <p><img src={clientImg} alt="" /></p>
              <p><img src={clientImg3} alt="client" /></p>
              <p className='client-number'><span>10k</span></p>
              <div><p>Trusted by our clients</p></div>

            </div>

          </div>
          <div className="hero-img">
            <div className='hero-img-container'>
              <img src={heroGirl} alt="Girl" />
              <p > <span>
                <div className='hero-universites'>
                  <p><HomeModernIcon className='tw-h-8 tw-w-8 tw-text-[#96c5f4]' /></p>
                  <div>
                    <p>120+</p>
                    <p className='tw-whitespace-nowrap'>Universities Worldwide</p>
                  </div>
                </div>
                <div className='hero-canada-img'>
                  <img src={CA} alt="" className='tw-h-9' />
                </div>
                <div className='hero-us-img'>
                  <img src={US} alt="" className='tw-h-8' />
                </div>
                <div className='hero-uk-img'>
                  <img src={UK} alt="" className='tw-h-8' />
                </div>
                <div className='hero-australia-img'>
                  <img src={NZ} alt="" className='tw-h-8' />
                </div>
              </span></p>

            </div>
          </div>
        </div>
      </div>
      <div className='hero-bottom tw-flex tw-justify-between'>
        <div className='tw-p-6'>
          <div className='tw-flex tw-items-center tw-gap-3'>
            <p><img src={frame} alt="" /></p>
            <p>Live Classes</p>
          </div>
          <p className='tw-pt-3'>We empower students with a platform to unlock their potential and connect them to opportunities around the world.</p>
        </div>
        <div className='tw-p-6'>
          <div className='tw-flex tw-items-center tw-gap-3'>
            <p><img src={frame} alt="" /></p>
            <p>Live Classes</p>
          </div>
          <p className='tw-pt-3'>We empower students with a platform to unlock their potential and connect them to opportunities around the world.</p>
        </div>
        <div className='tw-p-6'>
          <div className='tw-flex tw-items-center tw-gap-3'>
            <p><img src={frame} alt="" /></p>
            <p>Live Classes</p>
          </div>
          <p className='tw-pt-3'>We empower students with a platform to unlock their potential and connect them to opportunities around the world.</p>
        </div>
      </div>
      <ConnectingUniversities />
      <GetScholarships />
      <VisaCalculator />
      <OurPartnerUniversities />
      <OurEvents />
      <SuccessStories />
      <OurBlogs />
      <div>
        <StudingAbroadBanner img={girlImg} />
      </div>
      <Footer />
    </div>
  )
}

export default HeroSection