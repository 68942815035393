 export const MenuList = [{
    name: "Our Courses",
    reUrl: '/our-coursex'
},{
    name: "Scholarships",
    reUrl: '/scholarships'
},{
    name: "Blog",
    reUrl: '/blog'
},{
    name: "QnA",
    reUrl: '/q-n-a'
},{
    name: "Events",
    reUrl: '/events'
}] 