import React from 'react';
import StarImg from '../../Assets/Images/magic-star.png'

function ScholarCard({ name, rating, country, image }) {

    return (
        <div className='center scholar-card'>
            <div className='rating tw-p-1 tw-flex'>
                <img src={StarImg} alt='rating' />
                <span className='bold'>{rating}</span>
            </div>
            <img src={image} alt={name} />
            <div className='bold tw-p-1.5'>{name}</div>
            <p>{country}</p>
        </div>
    )
}

export default ScholarCard;
