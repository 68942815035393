import React, { useState } from 'react'
import Heading from '../Components/Heading/Heading'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'
import QueCard from '../Components/QnA/QueCard'
import CA from '../Assets/Images/CA.png'
import AU from '../Assets/Images/197589.png'
import UK from '../Assets/Images/UK.png'
import IN from '../Assets/Images/IN.png'
import IL from '../Assets/Images/IE.png'
import EU from '../Assets/Images/EU.png'
import sponsor from '../Assets/Images/sponsor.png'
import PrimaryButton from '../Components/Buttons/PrimaryButton'
import { ChevronLeft } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SecondaryButton from '../Components/Buttons/SecondaryButton'
import girlImg from '../Assets/Images/wepik-export-20230811074638IC3g 1.png'
import StudingAbroadBanner from '../Components/StudingAbroadBanner/StudingAbroadBanner'
import Footer from '../Components/Footer/Footer'

const questions = [
  {
    question: 'Which country is a better choice?',
  },
  {
    question: 'Which university is a better choice?',
  },
  {
    question: 'Which country is a better choice?',
  },
  {
    question: 'Which university is a better choice?',
  },
  {
    question: 'Which country is a better choice?',
  },
  {
    question: 'Which university is a better choice?',
  }
];

const tags = ['All', 'Courses', 'Visa Processing', 'Study in UK', 'Insurance', 'Expenses', 'Jobs', 'Study Abroad']

function QnA() {
  const [activeTag, setActiveTag] = useState(tags[0]);

  return (
    <>
      <Heading />
      <div className='scholarship'>
        <div className='breadboard'>
          <p className='tw-text-[16px]'>Home</p>
          <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
          <p className='tw-text-[18px] tw-text-[#2b8ae7]'>QnA</p>
        </div>
        <div className='tw-pb-10'>
          <div className="filtersearch">
            <p>Have a Question?</p>
            <p>If you have any question you can ask below or enter what you are looking for!</p>
            <div className='filtersearch-input'>
              <div className="filtersearch-input_buttons">
                <span><AdjustmentsVerticalIcon className='tw-h-7 tw-w-7' /></span> Filter
              </div>
              <div className="filtersearch-input_field">
                <input type="text" placeholder='Search for answers' />
              </div>
              <div className="filtersearch-input_submit">
                <div ><button > Search</button></div>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-mt-10 QnA-tabs">
          {tags.map(tag => <p onClick={() => setActiveTag(tag)} className={activeTag === tag ? "tw-text-white tw-bg-[#0072E3]" : ""}>{tag}</p>)}
        </div>
        <div className='tw-mt-10 wrapper'>
          <div className='tw-flex tw-gap-6 QnA'>

            <div className='country-wise tw-p-4'>
              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2 title' >
                <div className='bold'>Country Wise</div>
              </div>
              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2' >
                <p>  <img src={AU} className='tw-h-8 tw-w-8' alt="" /></p>
                <p>Australia</p>
              </div>
              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2' >
                <p>  <img src={CA} className='tw-h-8 tw-w-8' alt="" /></p>
                <p>Canada</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2' >
                <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>UK</p>
              </div>


              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={AU} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>New Zealand</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={IL} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>Ireland</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={IN} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>India</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>Bangladesh</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={EU} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>Europe</p>
              </div>

              <div className='tw-cursor-pointer tw-py-2.5 tw-flex tw-items-center tw-flex-start tw-gap-2'>
                <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
                <p>Dubai</p>
              </div>
            </div>
            <div>
              {questions.map((queObj) => <QueCard que={queObj.question} />)}


              <div className="pagination-head tw-p-4 tw-flex tw-justify-between">

                <SecondaryButton className='tw-py-2.5 tw-px-6'>

                  <span className='tw-whitespace-nowrap'>
                    <ChevronLeft /> Previous
                  </span>
                </SecondaryButton>
                1 of 80 results
                <PrimaryButton className='tw-py-2.5 tw-px-6'>
                  <span className='tw-whitespace-nowrap'>
                    Next <ChevronRightIcon />
                  </span>
                </PrimaryButton>

              </div>
            </div>
            <div>

              <div className='tw-p-4 que-in-mind'>
                <div className='bold tw-mb-4'>Have a Question in mind?</div>
                <div>
                  <PrimaryButton className={'tw-py-2.5 tw-px-28'}>Ask a Question</PrimaryButton>
                </div>
              </div>

              <div className='tw-p-4 top-contributors tw-mt-4'>
                <div className='tw-pb-3 title' >
                  <div className='bold'>Top Contributors</div>
                </div>

                <div className='tw-flex tw-pt-6 tw-cursor-pointer'>
                  <div><img src={'images/Avatar2.png'} alt="" className="avatar" /></div>
                  <div className='tw-py-1.5 tw-px-3'>
                    <diV className='author'>Madelyn Torff</diV>
                    <p className='author-sub'>Students Helped: 427518</p>
                  </div>
                </div>
                <div className='tw-flex tw-pt-6 tw-cursor-pointer'>
                  <div><img src={'images/Avatar3.png'} alt="" className="avatar" /></div>
                  <div className='tw-py-1.5 tw-px-3'>
                    <diV className='author'>Marley Vetrovs</diV>
                    <p className='author-sub'>Students Helped: 106725</p>
                  </div>
                </div>
                <div className='tw-flex tw-pt-6 tw-cursor-pointer'>
                  <div><img src={'images/Avatar4.png'} alt="" className="avatar" /></div>
                  <div className='tw-py-1.5 tw-px-3'>
                    <diV className='author'>Hanna Press</diV>
                    <p className='author-sub'>Students Helped: 965742</p>
                  </div>
                </div>
                <div className='tw-flex tw-pt-6 tw-cursor-pointer'>
                  <div><img src={'images/Avatar2.png'} alt="" className="avatar" /></div>
                  <div className='tw-py-1.5 tw-px-3'>
                    <diV className='author'>Tatiana Lipshutz</diV>
                    <p className='author-sub'>Students Helped: 482673</p>
                  </div>
                </div>
                <div className='tw-flex tw-pt-6 tw-cursor-pointer'>
                  <div><img src={'images/Avatar4.png'} alt="" className="avatar" /></div>
                  <div className='tw-py-1.5 tw-px-3'>
                    <diV className='author'>Marcus Mango</diV>
                    <p className='author-sub'>Students Helped: 821643</p>
                  </div>
                </div>
              </div>
              <div className='tw-p-4 sponsored-links tw-mt-4'>
                <div className='tw-pb-3 title' >
                  <div className='bold'>Sponsored Links</div>
                </div>

                <div className='tw-pt-6 tw-pb-1 tw-cursor-pointer tw-border-b-2'>
                  <div className=''><img src={sponsor} alt="" className="tw-w-full tw-rounded-lg" /></div>
                  <div className='tw-flex tw-justify-between'>
                    <div className='tw-py-3'>
                      <diV className='sponsor-title'>GNIOT BA LLB Admissions 2023</diV>
                      <p className='sponsor-sub'>Approved by BCI</p>
                    </div>
                    <div className='tw-py-3'>
                      <PrimaryButton className={'tw-py-2.5 tw-px-6'}>Apply</PrimaryButton>
                    </div>
                  </div>
                </div>

                <div className='tw-pt-6 tw-pb-1 tw-cursor-pointer tw-border-b-2'>
                  <div className=''><img src={sponsor} alt="" className="tw-w-full tw-rounded-lg" /></div>
                  <div className='tw-flex tw-justify-between'>
                    <div className='tw-py-3'>
                      <diV className='sponsor-title'>GNIOT BA LLB Admissions 2023</diV>
                      <p className='sponsor-sub'>Approved by BCI</p>
                    </div>
                    <div className='tw-py-3'>
                      <PrimaryButton className={'tw-py-2.5 tw-px-6'}>Apply</PrimaryButton>
                    </div>
                  </div>
                </div>

                <div className='tw-pt-6 tw-pb-1 tw-cursor-pointer tw-border-b-2'>
                  <div className=''><img src={sponsor} alt="" className="tw-w-full tw-rounded-lg" /></div>
                  <div className='tw-flex tw-justify-between'>
                    <div className='tw-py-3'>
                      <diV className='sponsor-title'>GNIOT BA LLB Admissions 2023</diV>
                      <p className='sponsor-sub'>Approved by BCI</p>
                    </div>
                    <div className='tw-py-3'>
                      <PrimaryButton className={'tw-py-2.5 tw-px-6'}>Apply</PrimaryButton>
                    </div>
                  </div>
                </div>

                <div className='tw-pt-6 tw-pb-1 tw-cursor-pointer tw-border-b-2'>
                  <div className=''><img src={sponsor} alt="" className="tw-w-full tw-rounded-lg" /></div>
                  <div className='tw-flex tw-justify-between'>
                    <div className='tw-py-3'>
                      <diV className='sponsor-title'>GNIOT BA LLB Admissions 2023</diV>
                      <p className='sponsor-sub'>Approved by BCI</p>
                    </div>
                    <div className='tw-py-3'>
                      <PrimaryButton className={'tw-py-2.5 tw-px-6'}>Apply</PrimaryButton>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>


        </div>
      </div>
      <div>
        <StudingAbroadBanner img={girlImg} />
      </div>
      <Footer />
    </>
  )
}

export default QnA