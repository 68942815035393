import React, { useState } from 'react';
import SecondaryButton from '../Buttons/SecondaryButton';
import GreImg from '../../Assets/Images/Image.png'
import Gre2Img from '../../Assets/Images/gre2.png'
import Gre3Img from '../../Assets/Images/gre3.png'
import EventCard from './EventCard';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const TABS = {
    ALL: 'ALL',
    UPCOMING: 'UPCOMING',
    PAST: 'PAST'
}

function OurEvents() {
    const [activeTab, setActiveTab] = useState(TABS.ALL);

    return (
        <div className='wrapper tw-mt-10 center our-events'>
            <div className='connecting-container'>
                <h1 className='heading1 center'>
                    Our <span className='text-secondary bold'>Events</span>
                </h1>
                <p className='tw-p-6'>Our app connects to all the most popular universities.<br />Your academic applications are validated by our team and send to universities. </p>
                <div className='tw-my-10 tw-flex tw-justify-center'>
                    <ul className='tabs tw-w-fit'>
                        <li onClick={() => setActiveTab(TABS.ALL)} className={activeTab === TABS.ALL ? 'active' : ''}> All </li>
                        <li onClick={() => setActiveTab(TABS.UPCOMING)} className={activeTab === TABS.UPCOMING ? 'active' : ''}> Upcoming </li>
                        <li onClick={() => setActiveTab(TABS.PAST)} className={activeTab === TABS.PAST ? 'active' : ''}> Past </li>
                    </ul>
                </div>
                <div className='tw-flex tw-gap-4 tw-py-10 tablet:tw-flex-col tablet:tw-items-center'>
                    <EventCard image={GreImg} />
                    <EventCard image={Gre2Img} />
                    <EventCard image={Gre3Img} />
                </div>

                <div className='tw-flex'>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                    <div className='tw-mx-4 tw-flex tw-gap-8'>
                        <SecondaryButton className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronLeftIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                        <SecondaryButton className='tw-py-4 tw-px-10 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span className='text-secondary'> View all Events </span>
                            </div>
                        </SecondaryButton>
                        <SecondaryButton className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                    </div>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                </div>
            </div>
        </div>
    )
}

export default OurEvents;
