import React, { useEffect, useState } from 'react';
import indiaImg from '../../Assets/Images/IN.png'
import canadaImg from '../../Assets/Images/CA.png'
import ausImg from '../../Assets/Images/197589.png'
import usaImg from '../../Assets/Images/US.png'
import ukImg from '../../Assets/Images/UK.png'
import SecondaryButton from '../Buttons/SecondaryButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const countries = [
    {
        name: 'Canada',
        id: 1,
        image: canadaImg,
        universities: [...Array(15).keys()].map(i => ({ img: `/images/universities/Article-${i}.png`, name: '', id: i }))
    },
    {
        name: 'Australia',
        id: 2,
        image: ausImg,
        universities: [...Array(12).keys()].map(i => ({ img: `/images/universities/Article-${i + 1}.png`, name: '', id: i + 1 }))
    },
    {
        name: 'UK',
        id: 3,
        image: ukImg,
        universities: [...Array(7).keys()].map(i => ({ img: `/images/universities/Article-${i + 3}.png`, name: '', id: i + 3 }))
    },
    {
        name: 'USA',
        id: 4,
        image: usaImg,
        universities: [...Array(15).keys()].map(i => ({ img: `/images/universities/Article-${i}.png`, name: '', id: i }))
    },
    {
        name: 'India',
        id: 4,
        image: indiaImg,
        universities: [...Array(12).keys()].map(i => ({ img: `/images/universities/Article-${i + 1}.png`, name: '', id: i + 1 }))
    },
]

function OurPartnerUniversities() {
    const [activeCountryIndex, setActiveCountryIndex] = useState(0);

    const activeCountry = countries[activeCountryIndex];

    useEffect(() => {
        const swiper = setInterval(() => {
            setActiveCountryIndex((activeCountryIndex + 1) >= countries.length ? 0 : activeCountryIndex + 1);
        }, 3000);

        return () => clearInterval(swiper);
    }, [activeCountryIndex, setActiveCountryIndex]);

    return (
        <div className='wrapper tw-mt-10 center our-partner-uni'>
            <div className='connecting-container tw-py-12'>
                <h1 className='heading1 center bold'>
                    Our Partner <span className='text-secondary bold'>Universities</span>
                </h1>
                <p className=''>Our app connects to all the most popular universities.<br />Your academic applications are validated by our team and send to universities. </p>
                <div className='tw-my-10 tw-flex tw-flex-col tw-space-x-8'>
                    <div className='tw-flex tw-justify-center'>
                        <ul className='tw-flex countries tw-w-fit'>
                            {countries.map((country, i) => <li key={i} onClick={() => setActiveCountryIndex(i)} className={`tw-cursor-pointer tw-p-6 ${activeCountryIndex === i && 'active'}`}>
                                <img src={country.image} alt={country.name} className='country' />
                            </li>)}
                        </ul>
                    </div>
                    <div className='universities tw-p-10 tw-min-h-[440px]'>
                        <ul className='tw-grid tw-grid-cols-5'>
                            {activeCountry.universities.map((university, i) => <li key={i} className='tw-cursor-pointer'>
                                <img src={university.img} alt={university.name} />
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className='tw-flex'>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                    <div className='tw-mx-4 tw-flex tw-gap-8'>
                        <SecondaryButton handleClick={() => setActiveCountryIndex(activeCountryIndex > 0 ? activeCountryIndex - 1 : countries.length - 1)} className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronLeftIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                        <SecondaryButton className='tw-py-4 tw-px-10 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span className='text-secondary'> View all Universities</span>
                            </div>
                        </SecondaryButton>
                        <SecondaryButton handleClick={() => setActiveCountryIndex((activeCountryIndex + 1) >= countries.length ? 0 : activeCountryIndex + 1)} className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                    </div>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                </div>
            </div>
        </div>
    )
}

export default OurPartnerUniversities;
