import React, { useEffect, useState } from 'react';
import MeterImg from '../../Assets/Images/speedometer 1.png'
import FrameImg from '../../Assets/Images/Frame.png'
import PrimaryButton from '../Buttons/PrimaryButton';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

const visaTopics = [
    {
        title: 'Get your Score',
        description: 'Based on your academic history and interests, our AI recommends courses and universities that fit your interests.',
        image: MeterImg
    },
    {
        title: 'Shortlist & Apply',
        description: 'Shortlist & Apply content here.',
        image: FrameImg
    },
    {
        title: 'Submit & Get Accepted',
        description: 'Submit & Get Accepted content here.',
        image: MeterImg
    },
    {
        title: 'Start your Journey',
        description: 'Start your Journey content here',
        image: FrameImg
    }
]

function VisaCalculator() {
    const [activeTopicIndex, setActiveTopicIndex] = useState(0);

    const activeTopic = visaTopics[activeTopicIndex];

    useEffect(() => {
        const swiper = setInterval(() => {
            setActiveTopicIndex((activeTopicIndex + 1) >= visaTopics.length ? 0 : activeTopicIndex + 1);
        }, 3000);

        return () => clearInterval(swiper);
    }, [activeTopicIndex, setActiveTopicIndex]);

    return (
        <div className='wrapper tw-mt-10 center visa-calculator'>
            <div className='connecting-container'>
                <h1 className='heading1 center bold'>
                    Step wise guide to our
                </h1>
                <h1 className='heading1 center'>
                    <span className='text-secondary bold'>Visa Calculator</span>
                </h1>
                <p className='tablet:tw-m-auto tablet:tw-w-[80%]'>Our app connects to all the most popular universities.<br />Your academic applications are validated by our team and send to universities. </p>
                <div className='tw-my-10 tw-flex tw-space-x-8 tablet:tw-flex-col tablet:tw-gap-3'>
                    <div className='visa-info-topic'>
                        <ul className='tw-flex tw-flex-col tw-items-center'>
                            {visaTopics.map((topic, i) => <li key={i} onClick={() => setActiveTopicIndex(i)} className={`tablet:tw-w-[70%] tw-cursor-pointer tw-p-6 ${activeTopicIndex === i && 'active'}`}>
                                {topic.title}
                            </li>)}
                        </ul>
                    </div>
                    <div className='visa-info-card tw-p-10'>
                        <div>
                            <img src={activeTopic.image} alt={activeTopic.title} />
                        </div>
                        <h3 className='heading3 tw-my-4'>{(activeTopicIndex + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}. {activeTopic.title}</h3>
                        <p className='tw-text-left'>
                            {activeTopic.description}
                        </p>
                    </div>
                </div>
                <div className='tw-flex'>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                    <div className='tw-mx-4'>
                        <PrimaryButton className='tw-py-5 tw-px-16 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span className='tw-text-white'> Try Visa Calculator toady!</span>
                                <ArrowRightIcon className='tw-h-6 tw-w-6 tw-text-white' />
                            </div>
                        </PrimaryButton>
                    </div>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                </div>
            </div>
        </div>
    )
}

export default VisaCalculator;
