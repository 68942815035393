import universityImg from "../Assets/Images/The-University-of-Melbourne-logo-300x300 1.png";
import usUniversityImg from '../Assets/Images/University of Toronto - png.png'

export const programList = [

    {
        imgUrl: usUniversityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "FALL",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "WINTER",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "FALL",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "SPRING",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: usUniversityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "SUMMER",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: usUniversityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "FALL",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "WINTER",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "FALL",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: universityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "SPRING",
        lavelOfStudy: "Masters / PHD"
    },
    {
        imgUrl: usUniversityImg,
        cardHeading: "American University Emergin Global Leader Scholarship",

        institue: "University Of Toronto",
        tutionFee: "$15.1k",
        intake: "Aug 2023",
        intakeSeason: "SUMMER",
        lavelOfStudy: "Masters / PHD"
    },
]