
import HeroSection from './Pages/HeroSection';
import './Styling/Sass/index.css'
import './Styling/input.css'

function App() {
  return (
    <div className="unifinder">
      <div>
        <HeroSection />
      </div>
    </div>
  );
}

export default App;
