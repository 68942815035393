import React from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'

function FilterSearch() {
  return (
    <div className="filtersearch">
        <p>Find Scholarship that suits you the best</p>
        <p>Unifinders works hand-in-hand for partner institutions. We support marketing, brand, building, student diversity and international students recruitment.</p>
        <div className='filtersearch-input'>
<div className="filtersearch-input_buttons">
    <span><AdjustmentsVerticalIcon className='tw-h-7 tw-w-7' /></span> Filter 
</div>
<div className="filtersearch-input_field">
    <input type="text" placeholder='Search for scholarship' />
</div>
<div className="filtersearch-input_submit">
<div ><button > Search</button></div>
</div>
        </div>
    </div>
  )
}

export default FilterSearch