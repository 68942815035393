import React, { useState } from 'react'
import CA from '../../Assets/Images/CA.png'
import AU from '../../Assets/Images/197589.png'
import UK from '../../Assets/Images/UK.png'
import IN from '../../Assets/Images/IN.png'
import IL from '../../Assets/Images/IE.png'
import EU from '../../Assets/Images/EU.png'
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import bgImage from "../../Assets/Images/browse-by-country-img.jpeg"
import { ArrowOutward } from '@mui/icons-material';

const TABS = {
  AU: 'AU',
  CA: 'CA',
  USA: 'USA',
  UK: 'UK',
  NZ: 'NZ',
  IL: 'IL',
  IN: 'IN',
  BGD: 'BGD',
  EU: 'EU',
  DB: 'DB',
}

function BrowseByCountry() {
  const [activeTab, setActiveTab] = useState(TABS.AU);
  return (
    <div className='browse_by_county'>
      <div className="browse_by_county-tabs">
        <div className={activeTab === TABS.AU ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} onClick={()=> setActiveTab('AU')}>
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2' >
        <p>  <img src={AU} className='tw-h-8 tw-w-8' alt="" /></p>
          <p>Australia</p>
          </div>
          {activeTab === TABS.AU && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold tw-text-[#0072E3]' /></p>}
          </div>
        <div className={activeTab === TABS.CA ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} onClick={()=> setActiveTab('CA')}>
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2' >
        <p>  <img src={CA} className='tw-h-8 tw-w-8' alt="" /></p>
          <p>Canada</p>
          </div>
          {activeTab === TABS.CA && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.UK ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} onClick={()=> setActiveTab('UK')}>
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2' >
        <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
          <p>UK</p>
          </div>
          {activeTab === TABS.UK && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.NZ ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={AU} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>New Zealand</p>
          </div>
          {activeTab === TABS.NZ && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.IL ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={IL} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>Ireland</p>
          </div>
          {activeTab === TABS.IL && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.IN ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={IN} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>India</p>
          </div>
          {activeTab === TABS.IN && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.BGD ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>Bangladesh</p>
          </div>
          {activeTab === TABS.BGD && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.EU ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={EU} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>Europe</p>
          </div>
          {activeTab === TABS.EU && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>
        <div className={activeTab === TABS.DB ? 'tw-flex tw-items-center tw-justify-between active' : 'tw-flex tw-items-center tw-justify-between'} >
          <div className='tw-flex tw-items-center tw-flex-start tw-gap-2'>
        <p>  <img src={UK} className='tw-h-7 tw-w-7' alt="" /></p>
        <p>Dubai</p>
          </div>
          {activeTab === TABS.DB && <p><ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' /></p>}
          </div>


  
        
    
      
        
      </div>
      <div className="browse_by_county-content">
        {activeTab === "AU" && <div className="browse_by_county-content_item">
          <div className="browse_by_county-content_item-details">
            <div>
            <div>
              <h1>Why Australia ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>About Australia</p>
              <p>Top Cities</p>
              <p>Visa Info</p>
              <p>Compare with other Country</p>
              <p>Compare with other Country</p>
              <p>Reasons to study in Australia</p>

            </div>
            <div>
              <h1>Live in Australia ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Permanent Residence PR</p>
              <p>Accordination</p>
              <p>Education & Living Costs</p>
              <p>Daily Life in Australia</p>
              <p>Plan your arrival</p>
              <p>Insurance</p>
              <p>Visa Compliance</p>
              <p>People & Culture</p>
              <p>Banking in Australia</p>
              <p>Transport in Australia</p>
              <p>Nepalese students in Australia</p>


            </div>
            </div>
            <div>
            <div>
                <h1>Work in Australia <span className='outward-line'><ArrowOutward /></span></h1>
                <p>Jobs in Australia</p>
                <p>Work while your study</p>
                <p>Employabilities Resources</p>
                <p>Tax and superannuation</p>
                <p>Volunteer and industry experiences</p>
                <p>know your rights</p>
                <p>know your rights</p>
                <p>study australia industry experiences program</p>
                <p>Getting a job</p>
                <p>Internship in Australia</p>
            </div>
            <div>
            <h1>Study in Australia <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Australian Education</p>
              <p>University & Higher Education</p>
              <p>Vocational Education </p>
              <p>Scholarships</p>
              <p>Governinging Bodies</p>
              <p>Cost of studying</p>
              <p>Post Study Work visa</p>
              <p>Student Visa Requirements</p>
              <p>International Student in Australia</p>
              <p>A guide to study in Australia</p>
              <p>Study in australia from Nepal</p>
            </div>
            </div>
          </div>
          <div className='browse_by_county-content_item-img'>
              <img src={bgImage} alt="" />
          </div>
        </div>}
        {activeTab === "CA" && <div className="browse_by_county-content_item">
          <div className="browse_by_county-content_item-details">
            <div>
            <div>
              <h1>Why Canada ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>About Australia</p>
              <p>Top Cities</p>
              <p>Visa Info</p>
              <p>Compare with other Country</p>
              <p>Compare with other Country</p>
              <p>Reasons to study in Australia</p>

            </div>
            <div>
              <h1>Live in Canada ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Permanent Residence PR</p>
              <p>Accordination</p>
              <p>Education & Living Costs</p>
              <p>Daily Life in Australia</p>
              <p>Plan your arrival</p>
              <p>Insurance</p>
              <p>Visa Compliance</p>
              <p>People & Culture</p>
              <p>Banking in Australia</p>
              <p>Transport in Australia</p>
              <p>Nepalese students in Australia</p>


            </div>
            </div>
            <div>
            <div>
                <h1>Work in Canada <span className='outward-line'><ArrowOutward /></span></h1>
                <p>Jobs in Australia</p>
                <p>Work while your study</p>
                <p>Employabilities Resources</p>
                <p>Tax and superannuation</p>
                <p>Volunteer and industry experiences</p>
                <p>know your rights</p>
                <p>know your rights</p>
                <p>study australia industry experiences program</p>
                <p>Getting a job</p>
                <p>Internship in Australia</p>
            </div>
            <div>
            <h1>Study in Canada <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Australian Education</p>
              <p>University & Higher Education</p>
              <p>Vocational Education </p>
              <p>Scholarships</p>
              <p>Governinging Bodies</p>
              <p>Cost of studying</p>
              <p>Post Study Work visa</p>
              <p>Student Visa Requirements</p>
              <p>International Student in Australia</p>
              <p>A guide to study in Australia</p>
              <p>Study in australia from Nepal</p>
            </div>
            </div>
          </div>
          <div className='browse_by_county-content_item-img'>
              <img src={bgImage} alt="" />
          </div>
        </div>}
        {activeTab === "UK" && <div className="browse_by_county-content_item">
          <div className="browse_by_county-content_item-details">
            <div>
            <div>
              <h1>Why UK ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>About Australia</p>
              <p>Top Cities</p>
              <p>Visa Info</p>
              <p>Compare with other Country</p>
              <p>Compare with other Country</p>
              <p>Reasons to study in Australia</p>

            </div>
            <div>
              <h1>Live in UK ? <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Permanent Residence PR</p>
              <p>Accordination</p>
              <p>Education & Living Costs</p>
              <p>Daily Life in Australia</p>
              <p>Plan your arrival</p>
              <p>Insurance</p>
              <p>Visa Compliance</p>
              <p>People & Culture</p>
              <p>Banking in Australia</p>
              <p>Transport in Australia</p>
              <p>Nepalese students in Australia</p>


            </div>
            </div>
            <div>
            <div>
                <h1>Work in UK <span className='outward-line'><ArrowOutward /></span></h1>
                <p>Jobs in Australia</p>
                <p>Work while your study</p>
                <p>Employabilities Resources</p>
                <p>Tax and superannuation</p>
                <p>Volunteer and industry experiences</p>
                <p>know your rights</p>
                <p>know your rights</p>
                <p>study australia industry experiences program</p>
                <p>Getting a job</p>
                <p>Internship in Australia</p>
            </div>
            <div>
            <h1>Study in UK <span className='outward-line'><ArrowOutward /></span></h1>
              <p>Australian Education</p>
              <p>University & Higher Education</p>
              <p>Vocational Education </p>
              <p>Scholarships</p>
              <p>Governinging Bodies</p>
              <p>Cost of studying</p>
              <p>Post Study Work visa</p>
              <p>Student Visa Requirements</p>
              <p>International Student in Australia</p>
              <p>A guide to study in Australia</p>
              <p>Study in australia from Nepal</p>
            </div>
            </div>
          </div>
          <div className='browse_by_county-content_item-img'>
              <img src={bgImage} alt="" />
          </div>
        </div>}
      </div>
    </div>
  )
}

export default BrowseByCountry