import React, { useState } from 'react'
import { eventsList } from '../../Constants/eventsList'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import {LocationOn, Timer } from '@mui/icons-material'
import { useNavigate } from 'react-router'

const TABS = {
  STUDENT: 'STUDENT',
  WHO_WE_ARE: 'WHO_WE_ARE',
  UNIVERSITY: 'UNIVERSITY'
}

function EventsPage() {
  const [activeTab, setActiveTab] = useState(TABS.STUDENT);
  const navigate = useNavigate();
  return (
    <div>
      <div className="events-tabs">
      <div className='wrapper center connecting-universities'>
      <div className='connecting-container'>
                <ul className='tabs tw-w-fit'>
                    <li onClick={() => setActiveTab(TABS.STUDENT)} className={activeTab === TABS.STUDENT ? 'active' : ''}> All </li>
                    <li onClick={() => setActiveTab(TABS.WHO_WE_ARE)} className={activeTab === TABS.WHO_WE_ARE ? 'active' : ''}> Upcoming </li>
                    <li onClick={() => setActiveTab(TABS.UNIVERSITY)} className={activeTab === TABS.UNIVERSITY ? 'active' : ''}> Past </li>
                </ul>
            </div>
            </div>
      </div>
      <div className="events-cards tw-grid tw-grid-cols-3 tw-gap-6">
      {eventsList.map((item)=> {
        let concatedDateOne = item.date.slice(0,2)
        let concatedDateTwo = item.date.slice(3,6)
        
        return (
          <div onClick={()=>navigate(`/events/${item.id}`)} className="events-cards_items tw-cursor-pointer" key={item.title}>
            <p><img src={item.imgUrl} /> <span className='broke-date'> <span>{concatedDateOne}</span><span>{concatedDateTwo}</span> </span></p>
            <p>{item.title}</p>
            <p>{item.description}</p>
            <p><Timer /> {item.time}</p>
            <p><LocationOn /> {item.location}</p>
            <p>Learn More <ArrowRightIcon className='tw-h-5 tw-w-5 learn-more-arrow' /></p>
          </div>
        )
      })}
      </div>
    </div>
  )
}

export default EventsPage