import React from 'react'
import Heading from '../Components/Heading/Heading'
import FilterSearch from '../Components/Scholarships/FilterSearch'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import Blogs from '../Components/Blog/Blogs'
import Footer from '../Components/Footer/Footer'
import StudingAbroadBanner from '../Components/StudingAbroadBanner/StudingAbroadBanner'
import imgGirl from '../Assets/Images/Events/wepik-export-20230814102810Li1u.png'

function Blog() {
  return (
    <>
    <Heading />
    <div className='blog-container'>
        <div className='breadboard'>
          <p className='tw-text-[16px]'>Home</p>
          <span><ChevronRightIcon className='tw-h-5 tw-w-5' /></span>
          <p className='tw-text-[18px] tw-text-[#2b8ae7]'>Blogs</p>
        </div>
        <div>
          <FilterSearch />
        </div>
        <div>
          <Blogs />
        </div>
        
        </div>
        <div>
        <StudingAbroadBanner img={imgGirl} />
        </div>
        <div>
          <Footer />
        </div>
    </>
  )
}

export default Blog