import imageOne from '../Assets/Images/Events/Image.png'
import imageTwo from '../Assets/Images/Events/Image2.png'
import imageThree from '../Assets/Images/Events/Image3.png'
export const eventsList = [
    {
        id: '1',
        imgUrl: imageOne,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
    {
        id: '2',
        imgUrl: imageTwo,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
    {
        id: '3',
        imgUrl: imageThree,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
    {
        id: '4',
        imgUrl: imageOne,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
    {
        id: '5',
        imgUrl: imageTwo,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
    {
        id: '6',
        imgUrl: imageThree,
        date: "15 Dec",
        title: "Educate the World Conference",
        description: "Our app connects to all the most popular universities...",
        descriptionFull: "Lorem ipsum dolor sit amet consectetur. Morbi vel a dui odio odio tristique. Turpis nunc accumsan nunc risus cursus diam tellus nunc. Non placerat quam in quis at dictum. Id diam nisi nulla lacus lacus risus. Nunc nunc aliquam in nulla nibh. Suspendisse consequat facilisi ut purus bibendum id aliquam. Porta a massa pellentesque blandit vel amet est. Donec non diam sed est senectus aliquet et. Massa velit at tincidunt purus urna amet vel. Amet interdum sit amet urna feugiat ullamcorper sit orci scelerisque. Arcu eu nisi id volutpat justo amet amet. Rhoncus at risus id molestie aliquet odio suscipit.\n\nMattis vestibulum vitae suspendisse tortor sagittis turpis. Velit at ornare purus est pretium euismod est amet. Ac velit ac condimentum massa. Et potenti dui sagittis faucibus cras. Nibh tincidunt ut dignissim pellentesque convallis elit odio. Nibh amet ultrices leo lobortis ultrices laoreet. Volutpat curabitur elit pellentesque diam condimentum nisi aenean nulla aliquet. Quis mauris praesent venenatis diam vel.\n\nNunc a sed blandit volutpat sed etiam scelerisque pharetra cras. Justo eget diam purus cursus nunc. Dui nulla aliquam non turpis dui est massa cras. Commodo tristique amet cursus ridiculus et interdum sociis lacus. Scelerisque fringilla laoreet facilisis duis odio. Morbi adipiscing cursus volutpat ipsum tempor.",
        time: "12:00 to 3:00",
        location: "Harmony Seminar Hall"
    },
]