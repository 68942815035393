import React from 'react';
import SecondaryButton from '../Buttons/SecondaryButton';
import { blogsList } from '../../Constants/blogsList'
import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

function OurBlogs() {

    return (
        <div className='wrapper tw-mt-8 tw-pt-12 our-events'>
            <div className='connecting-container'>
                <h1 className='heading1 center bold'>
                    Read <span className='text-secondary '>Our Blogs</span>
                </h1>
                <div className='tw-my-16'>

                    <div className="blogs-cards tw-grid tw-grid-cols-3 tw-gap-6 tablet:tw-grid-cols-2 mobile:tw-grid-cols-1">
                        {blogsList.slice(0, 3).map((item) => (
                            <div className="blogs-cards_items" key={item.title}>
                                <p><img src={item.imgUrl} alt="" /></p>
                                <p>{item.date} <span>{item.location}</span></p>
                                <p>{item.title}</p>
                                <p>{item.description}</p>
                                <p>Learn More <ArrowRightIcon className='tw-h-5 tw-w-5 learn-more-arrow' /></p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='tw-flex'>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                    <div className='tw-mx-4 tw-flex tw-gap-8'>
                        <SecondaryButton className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronLeftIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                        <SecondaryButton className='tw-py-4 tw-px-10 tw-rounded-xl'>
                            <div className='tw-flex tw-nowrap tw-gap-2'> <span className='text-secondary'> View all Blogs </span>
                            </div>
                        </SecondaryButton>
                        <SecondaryButton className='tw-p-4 tw-rounded-xl text-secondary'>
                            <ChevronRightIcon className='tw-h-5 tw-w-5 tw-font-bold' />
                        </SecondaryButton>
                    </div>
                    <div className='tw-h-0.5 tw-mt-4 tw-w-2/5'></div>
                </div>
            </div>
        </div>
    )
}

export default OurBlogs;
